<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>场景列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入场景名称" clearable></el-input>
              <!-- <el-select v-model="params.store_size" placeholder="投放点大小" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.store_type" placeholder="投放点类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in storeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.lay_type" placeholder="铺设类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in layTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.group_id" placeholder="请选择需搜索企业" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1">
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select> -->
              <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;">添加场景</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="场景名称" prop="name" align="left"></el-table-column>
          <el-table-column label="寺院" prop="temple.name" align="left"></el-table-column>
          <el-table-column label="佛像" prop="buddhas_name" align="left" width="350">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>佛像: {{ scope.row.buddhas_name }}</p>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.buddhas_name}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="created_at" align="left" width="170" ></el-table-column>
          <!-- <el-table-column label="信用代码" prop="credit_code" align="center" width="170"></el-table-column> -->

          <el-table-column label="操作" width="100" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
              >
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" v-if="(scope.row.status !==2 || scope.row.status !==3) && admin_permission.indexOf('update')>-1" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                    <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          
          <!-- <div class="word">可提现总金额:  <span>{{total_amount}}</span></div> -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>
    <el-drawer
      title="更改场景信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加场景"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    
    <el-dialog
      title="修改分润比例"
      :visible.sync="dialogPerVisible"
      width="30%"
      :before-close="handleClose">
      <div style="margin-top:20px;">分润</div>
      <el-input v-model="devided" placeholder="请输入分润比例" style="width:300px;margin-top:10px;">
        <template slot="append">%</template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirmPer()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改价格"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>单位</div>
      <el-select v-model="unit" clearable filterable placeholder="请选择单位" style="width:300px;margin-top:10px;">
        <el-option
          v-for="item in unitList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <div style="margin-top:20px;">单价</div>
      <el-input v-model="unit_price" placeholder="请输入单价" style="width:300px;margin-top:10px;"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      title="推广内容"
      :visible.sync="dialogMediaVisible"
      :before-close="handleClose">
      <div style="display: flex;justify-content: flex-start;">
        <div>
          <div style="font-size:14px;margin-bottom: 20px;padding-left:10px;">请上传推广图片 </div>
          <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" :num="1" path="store" :multiple="true" :images="storeImageUrl" style=" box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.1);"></upload-qiniu>
        </div>
        <div style="margin-left: 40px;">
          <div style="font-size:15px;font-weight: 600;">推广标题 <span style="font-size:14px;color:darkgray;font-weight:400;margin-left:5px;">最大字数位12个字</span></div>
          <el-input v-model="mediaTitle" placeholder="最多输入12个字" style="width:300px;margin-top:10px;" maxlength="12"></el-input>
          <div style="font-size:15px;font-weight: 600;margin-top: 10px;">推广内容 <span style="font-size:14px;color:darkgray;font-weight:400;margin-left:5px;">最大字数位37个字</span></div>
          <el-input type="textarea" v-model="mediaContent" placeholder="最多输入37个字" autosize maxlength="37" style="width:300px;margin-top:10px;font-size:14px;"></el-input>
        </div>
        <div></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirmMedia()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { getSceneList, addData,editData,getTypeList,editPrice,editDevided,deleteData,storeAppointment,storeRefuse,updateDeduction,getMedia,editMedia} from "@/api/store.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";

  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import storeList from "@/views/components/storeList.vue";

  let page_name = 'place_list'
export default {
  name: "App",
  components:{addComp,editComp,storeList,UploadQiniu},

  data() {
    return {
      reason:'',
      admin_permission:[],
      dialogVisible:false,
      dialogPerVisible:false,
      dialogTableVisible:false,
      dialogRefuseVisible:false,
      dialogMediaVisible:false,//推广内容弹窗
      dialogDeductionVisible:false,//扣量弹窗
      mediaID:'',//推广内容弹窗ID
      mediaTitle:'',//推广标题
      mediaContent:'',//推广内容
      deductionID:'',
      deductionShow:false,
      deduction:0,
      deduction_protect :30,
      deduction_percent :10,
      deduction_begin:0,
      deduction_price_percent:10,
      refuseId:'',
      storeImageUrl:[],
      mediaList:[],
      status: [
        {id:"",label:"全部"},
        {id:0,label:"审核中"},
        {id:1,label:"审核通过"},
        {id:2,label:"审核失败"},
        {id:3,label:"失效点位"},
      ],
      treeCurrentNode:"",
      storeTypeOptions:[],
      sizeOptions:[
        {id:0,label:"普通"},
        {id:1,label:"中型"},
        {id:2,label:"大型"},
        {id:3,label:"超大型"}
      ],
      layTypeOptions:[
        {id:0,label:"独家"},
        {id:1,label:"共存"},
      ],
      unitList: [{
        value: 0,
        label: '每半小时'
      }, {
        value: 1,
        label: '每一小时'
      }],
      unit:'',
      unit_price:'',
      unitID:'',
      storeID:'',
      devidedID:'',
      provinceList:[],
      cityList:[],
      areaList:[],
      province_id:"",
      city_id:"",
      params: {
        page: 1,
        page_count: 10,
        temple_id:0
      },
      groupList:[],
      groupID:'',
      table:[],
      devided:0,
      service: [],
      editID: '',
      total: 0,
      outerVisible: false,
      innerVisible: false,
      addDrawer: false,
      editDrawer: false,
      direction: "rtl",
      addTradeParams: {
        name: "",
        parent_id: 0
      },
      visible: false,
      scopeForm:{
        id:'',
        sort: ''
      },
      temple_id:0
    };
  },

  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    this.searchType();
    
    this.groupID = this.$store.state.user.userData.group_id
    getGroups().then(res=>{
      if(res.code === 200){
        this.groupList = res.data
      }
    })
    getProvince().then(res=>{
      if(res.code === 200){
        this.provinceList = res.data
      }
    })
  },

  methods: {
    getTableData() {
      getSceneList(this.params).then(res=>{
        if(res.code === 200){
          this.table = res.data.data
          console.log(this.table)
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
        }
      })
    },
    searchData(){
      this.params.page=1
      this.getTableData()
    },
    searchType(){
      getTypeList().then(res=>{
        if(res.code === 200){
          this.storeTypeOptions = res.data
          console.log(this.storeTypeOptions)
        }
      })
    },
    provinceClick(){
      let that = this
      this.provinceList.forEach(item => {
        if(item.id == that.params.province_id ){
          that.province_id = item.province_id
          console.log(that.province_id)
        }
      });
      getCity(that.province_id).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.cityList = res.data
        }
      })
    },
    cityClick(){
      let that = this
      this.cityList.forEach(item => {
        if(item.id == that.params.city_id ){
          that.city_id = item.city_id
          console.log(that.city_id)
        }
      });
      getArea(that.city_id).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.areaList = res.data
        }
      })
    },
    command(e,item){
        console.log(e)
      if(e === 'refuse'){
        this.refuseId = item.id
        this.dialogRefuseVisible =true
      }
      if(e === 'appointment'){
        storeAppointment(item.id).then(res=>{
          if(res.code===200){
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '已通过',
              type: 'success'
            });
          }
        })
      }
      if(e === 'price'){
        this.dialogVisible=true
        this.unitID = item.id
      }
      if(e === 'percent'){
        this.dialogPerVisible=true
        this.devidedID = item.id
        this.devided = parseFloat(item.devided)*100
      }
      if(e === 'media'){
        this.dialogMediaVisible=true
        this.mediaID = item.id
        getMedia(this.mediaID).then(res=>{
          if(res.code===200){
            this.mediaList = res.data
            console.log(this.mediaList)
          }
        })
      }
      if(e === 'delete'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(item.id).then(res=>{
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          })
        })
        .catch(_ => {});
      }
      if(e === 'deduction'){
        console.log(item)
        this.dialogDeductionVisible = true
        this.deductionID = item.id
        this.deduction = item.deduction
        if(this.deduction==1){
          this.deductionShow = true
        }else{
          this.deductionShow = false
        }
        this.deduction_protect = item.deduction_protect
        this.deduction_begin = item.deduction_begin
        this.deduction_percent = item.deduction_percent
        this.deduction_price_percent = item.deduction_price_percent
        // getCode({group_id:item.id}).then(res=>{
        //   if(res.code === 200){
        //     this.code = res.data
        //   }
        // })
      }
    },
    deductionChange(e){
      console.log(e)
      if(e == true){
        this.deduction = 1
      }else{
        this.deduction = 0
      }
    },
    deductionCancel(){
      this.dialogDeductionVisible = false
    },
    deductionConfirm(){
      let params = {
        'deduction':this.deduction,
        'deduction_protect':this.deduction_protect,
        'deduction_begin':this.deduction_begin,
        'deduction_percent':this.deduction_percent,
        'deduction_price_percent':this.deduction_price_percent
      }
      updateDeduction(this.deductionID,params).then(res=>{
        if(res.code === 200){
          console.log(1)
          this.$message({
              message: "修改成功",
              type: "success"
          });
          this.dialogDeductionVisible = false
          this.getTableData()
        }
      })
    },
    refuse(){
      if(this.reason === ''){
        this.$message({
          message: "请输入拒绝理由",
          type: "success"
        });
        return false
      } 
      storeRefuse(this.refuseId,{reason:this.reason}).then(res=>{
        if(res.code === 200){
          this.dialogRefuseVisible = false
          this.getTableData()
          this.$notify({
            message: '已拒绝',
          });
        }
      })
    },
    cancel(){
      this.dialogVisible=false
      this.dialogRefuseVisible=false
      this.dialogPerVisible=false
    },
    confirm(){
      let _this = this
      this.dialogVisible=false
      let unitParams ={}
      unitParams.unit=this.unit
      unitParams.unit_price=this.unit_price
      editPrice(this.unitID,unitParams).then((res) => {
        if (res.code === 200) {
          _this.$message({
            message: "修改成功",
            type: "success"
          });
          _this.getTableData()
        }
      });
    },
    
    confirmPer(){
      let _this = this
      this.dialogPerVisible=false
      let unitParams ={}
      unitParams.devided=this.devided
      editDevided(this.devidedID,unitParams).then((res) => {
        if (res.code === 200) {
          _this.$message({
            message: "修改成功",
            type: "success"
          });
          _this.getTableData()
        }
      });
    },
    confirmMedia(){
      console.log(this.storeImageUrl)
      console.log(this.mediaTitle)
      console.log(this.mediaContent)
      let _this = this
      this.dialogMediaVisible=false
      let mediaParams ={}
      mediaParams.image=this.storeImageUrl[0]
      mediaParams.title=this.mediaTitle
      mediaParams.content=this.mediaContent
      editMedia(this.mediaID,mediaParams).then(res=>{
        if(res.code===200){
          _this.$message({
            message: "修改成功",
            type: "success"
          });
          _this.getTableData()
        }
      })
    },
    writeScope(id){
      console.log(id)
      this.scopeForm.id = id
      let tmp = [];
      this.service.forEach((value,key)=>{
        tmp[key] = value
        if (id === value.id){
          this.scopeForm.sort = parseInt(value.sort)
          tmp[key].write_scope = true
        }
      })
      this.service = tmp;
    },

    scopeChange(){
      editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
        if (res.code === 200){
          this.searchSet()
        }
      })
    },

    //选择TREE
    showTreeWorker(id, pid) {
      console.log(id)
      if(id === 3){
        this.params.deleted = 1;
      }else{
        this.params.deleted = '';
        this.params.status = id;
      }
        
        this.getTableData();
    },

    handleDetail(e){
      console.log(e)
      this.storeID=e
      this.dialogTableVisible = true
    },
    // 打开添加
    handleOpenAdd() {
      this.addDrawer = true;
    },
    // 关闭添加/修改
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.dialogPerVisible=false
          this.dialogDeductionVisible = false
          this.dialogMediaVisible = false
        })
        .catch((_) => {});
    },

    editTable(id){
      this.editID = id
      this.editDrawer = true
    },
    // 添加/修改成功
    handleDrawerSuccess() {
      this.getTableData()
      this.addDrawer = false;
      this.editDrawer = false;
      // this.searchSet()
      this.$refs.newForm.$refs.form.resetFields();
    },
    // 清空子组件
    clearContent() {
      this.$refs.newForm.$refs.form.resetFields();
    },
    
    // 上传图片删除、缓存、预览
    handlePicSuccess(e) {
      this.storeImageUrl = e;
    },
    handlePicRemove(e) {
      this.storeImageUrl = [];
    },
    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTableData();
    },
    nextpage() {
      this.params.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTableData();
    },
  }
};
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>