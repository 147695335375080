import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getTypeList(data = {}) {
  return axios.request({
    url: `${url}/customer/type`,
    method: "get",
    params: data,
  })
}

export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/type/${id}`,
    method: "get",
    params: data,
  })
}

export function addType(data = {}){
  return axios.request({
    url: `${url}/customer/type`,
    method: "post",
    data: data,
  })
}

export function deleteType(id,data = {}) {
  return axios.request({
    url: `${url}/customer/type/${id}`,
    method: "delete",
    data: data,
  })
}

export function getStatusList(data = {}) {
  return axios.request({
    url: `${url}/customer/status`,
    method: "get",
    params: data,
  })
}

export function getStatusById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/status/${id}`,
    method: "get",
    params: data,
  })
}

export function addStatus(data = {}){
  return axios.request({
    url: `${url}/customer/status`,
    method: "post",
    data: data,
  })
}

export function deleteStatus(id,data = {}) {
  return axios.request({
    url: `${url}/customer/status/${id}`,
    method: "delete",
    data: data,
  })
}

export function getOriginList(data = {}) {
  return axios.request({
    url: `${url}/customer/origin`,
    method: "get",
    params: data,
  })
}

export function getOriginById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/origin/${id}`,
    method: "get",
    params: data,
  })
}

export function addOrigin(data = {}){
  return axios.request({
    url: `${url}/customer/origin`,
    method: "post",
    data: data,
  })
}

export function deleteOrigin(id,data = {}) {
  return axios.request({
    url: `${url}/customer/origin/${id}`,
    method: "delete",
    data: data,
  })
}

export function getMaturityList(data = {}) {
  return axios.request({
    url: `${url}/customer/maturity`,
    method: "get",
    params: data,
  })
}

export function getMaturityById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/maturity/${id}`,
    method: "get",
    params: data,
  })
}

export function addMaturity(data = {}){
  return axios.request({
    url: `${url}/customer/maturity`,
    method: "post",
    data: data,
  })
}

export function deleteMaturity(id,data = {}) {
  return axios.request({
    url: `${url}/customer/maturity/${id}`,
    method: "delete",
    data: data,
  })
}


export function getSectorList(data = {}) {
  return axios.request({
    url: `${url}/customer/sector`,
    method: "get",
    params: data,
  })
}

export function getSectorById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/sector/${id}`,
    method: "get",
    params: data,
  })
}

export function addSector(data = {}){
  return axios.request({
    url: `${url}/customer/sector`,
    method: "post",
    data: data,
  })
}

export function deleteSector(id,data = {}) {
  return axios.request({
    url: `${url}/customer/sector/${id}`,
    method: "delete",
    data: data,
  })
}



