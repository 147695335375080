var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('el-alert',{staticStyle:{"margin-bottom":"10px"},attrs:{"title":"成功提示的文案","type":"success","effect":"dark"}}),_c('div',{staticClass:"container-content"},[_c('div',{staticClass:"container-left"},[_vm._m(0),_c('div',{staticClass:"map-box"},[_vm._m(1),_c('div',{staticClass:"map-content"},[_c('map-component')],1)]),_c('div',{staticClass:"order-time-box"},[_vm._m(2),_c('div',{staticClass:"content"},[_c('time-order-component')],1)]),_c('div',{staticClass:"order-month-box"},[_vm._m(3),_c('div',{staticClass:"content"},[_c('month-order-component')],1)]),_c('div',{staticClass:"order-year-box"},[_vm._m(4),_c('div',{staticClass:"content"},[_c('year-order-component')],1)])]),_c('div',{staticClass:"container-right"},[_c('div',{staticClass:"event-box"},[_vm._m(5),_c('div',{staticClass:"content"},[_c('new-event-component')],1)]),_c('div',{staticClass:"calendar-box"},[_c('calendar-component',{attrs:{"size":"mini"}})],1),_vm._m(6)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-box"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 数据概览 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})]),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"data-order"},[_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-list-title"},[_vm._v("今日收入(元)")]),_c('div',{staticClass:"data-list-content"},[_c('div',{staticClass:"data-list-content-number"},[_vm._v("123,354.59")]),_c('div',{staticClass:"data-list-content-other"},[_c('span',[_vm._v("较昨日")]),_c('i',{staticClass:"el-icon-caret-bottom"}),_c('span',{staticClass:"data-list-content-other-number"},[_vm._v("3600")])])])]),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-list-title"},[_vm._v("订单总额(元)")]),_c('div',{staticClass:"data-list-content"},[_c('div',{staticClass:"data-list-content-number"},[_vm._v("12,563,354.98")])])]),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-list-title"},[_vm._v("用户注册(人)")]),_c('div',{staticClass:"data-list-content"},[_c('div',{staticClass:"data-list-content-number"},[_vm._v("123,354")]),_c('div',{staticClass:"data-list-content-other"},[_c('span',[_vm._v("较昨日")]),_c('i',{staticClass:"el-icon-caret-bottom"}),_c('span',{staticClass:"data-list-content-other-number"},[_vm._v("3600")])])])]),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"data-list-title"},[_vm._v("活跃用户(人)")]),_c('div',{staticClass:"data-list-content"},[_c('div',{staticClass:"data-list-content-number"},[_vm._v("12,563,354")]),_c('div',{staticClass:"data-list-content-other"},[_c('span',[_vm._v("较昨日")]),_c('i',{staticClass:"el-icon-caret-bottom"}),_c('span',{staticClass:"data-list-content-other-number"},[_vm._v("3600")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 分布图 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 实时报表 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 月度订单 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 年度报表 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 最新事件 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})]),_c('div',{staticClass:"event-more"},[_vm._v("更多"),_c('i',{staticClass:"el-icon-d-arrow-right",staticStyle:{"margin-left":"5px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hear-place-box"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_vm._v(" 场所热度排名 "),_c('span',{staticClass:"title-update-time"},[_vm._v("2024-08-09 15:53 已更新")]),_c('i',{staticClass:"el-icon-refresh"})])])
}]

export { render, staticRenderFns }