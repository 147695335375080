<template>
    <div class="month-order-box">
        <div class="month-order-tool">

        </div>
        <div class="month-order-charts" id="monthCharts">

        </div>
    </div>
</template>
<script>
export default {
    props:{
        
    },
    data() {
        return {
            chart:'',
            dom:'',
            options:'',
            tooltip:{
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: function (params) {
                    console.log(params);
                    let string = ''
                    let amount = 0
                    let name = ''
                    let tmp = []
                    params.forEach((value,key)=>{
                        amount += value.value
                        name = value.name
                        tmp.push(
                            "<div style='display:inline-flex;align-items:center'>"+
                                "<div style='background-color:"+value.color+";width:5px;height:5px;border-radius:5px'></div>"+
                                "<span style='font-size: 12px;margin-left:10px;'>"+value.seriesName+"</span>"+
                                "<span style='font-size: 16px;font-weight:bold;margin-left:10px;'>"+value.value+"</span>"+
                            "</div><br/>"
                        )
                    })
                    string = name + "<br/>"+ "<div style='display:inline-flex;align-items:center;margin-bottom:10px;margin-top:10px;'>"+
                                "<span style='font-size: 12px;margin-left:10px;'>订单总额</span>"+
                                "<span style='font-size: 16px;font-weight:bold;margin-left:10px;'>"+amount+"</span>"+
                            "</div><br/>"+ 
                            tmp.join("")
                    return string;
                }
            },
            legend:{},
            grid:{
                top: '10%',
                left: '0%',
                right: '1%',
                bottom: '1%',
                containLabel: true
            },
            xAxis:{
                type: 'category',
                data: ['8/06', '8/07', '8/08', '8/09', '8/10', '8/11', '8/12']
            },
            yAxis:{
                type: 'value'
            },
            series:[
                {
                    name: '佛龛购买',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: false
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [320, 302, 301, 334, 390, 330, 320]
                },
                {
                    name: '佛龛供奉',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: false
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [120, 132, 101, 134, 90, 230, 210]
                },
                {
                    name: '祈福',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: false
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [220, 182, 191, 234, 290, 330, 310]
                },
                {
                    name: '行善',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: false
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [150, 212, 201, 154, 190, 330, 410]
                }
            ],

        };
    },

    mounted(){
        this.dom = document.getElementById('monthCharts');
        this.chart = this.$echarts.init(this.dom);
        console.log(this.chart)
        this.init()
    },

    methods: {
        init(){
            this.options = {
                tooltip:this.tooltip,
                legend:this.legend,
                grid:this.grid,
                xAxis:this.xAxis,
                yAxis:this.yAxis,
                series:this.series
            }
            this.chart.setOption(this.options)
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .month-order-box{
        width:100%;
        height: 400px;
        .month-order-charts{
            width:100%;
            height: 400px;
        }
    }
</style>
<style>

</style>